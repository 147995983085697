<template>
  <b-container
    fluid
    class="vh-100 px-0 mx-0"
  >
    <b-row class="h-100">
      <b-col
        cols="12"
        md="9"
        :style="{
          position: 'relative',
          backgroundImage: `url(${require('@/assets/images/background.jpg')})`,
          backgroundPosition: 'center 75%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          fontSize: '3vw',
          padding: '0px 75px',
          textShadow: '2px 2px #ff0000'
        }"
        class="d-flex justify-content-center align-items-center text-white text-center font-weight-bolder"
      >
        INVENTORY TRANSFER MANAGEMENT SYSTEM
      </b-col>
      <b-col
        cols="12"
        md="3"
        class="d-flex justify-content-center align-items-center border h-100"
      >
        <ValidationObserver
          ref="signinForm"
          v-slot="{ handleSubmit }"
        >
          <form
            role="form"
            @submit.prevent="handleSubmit(onSignIn)"
          >
            <div
              class="rounded py-3 px-2 px-md-5"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        vid="username"
                        name="username"
                        rules="required"
                      >
                        <b-form-group
                          label="Username"
                          label-for="username"
                          label-class="font-weight-bold"
                          class="mb-2"
                        >
                          <b-input
                            id="username"
                            v-model="credentials.username"
                            type="text"
                            placeholder="enter username here"
                            autocomplete="off"
                            :state="errors.length > 0 ? false : null"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        vid="password"
                        name="password"
                        rules="required"
                      >
                        <b-form-group
                          label="Password"
                          label-for="password"
                          label-class="font-weight-bold"
                          class="mb-2"
                        >
                          <b-input-group
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                          >
                            <b-input
                              id="password"
                              v-model="credentials.password"
                              placeholder="enter password here"
                              autocomplete="off"
                              :type="passwordType"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <b-input-group-append>
                              <b-button
                                class="pl-2 pr-1 text-dark"
                                variant="warning"
                                style="border-radius: 0px 3px 3px 0px; background-color: var(--iq-body-color); border: 1px solid #ccc; border-left: 0px solid transparent;"
                                :disabled="state.busy"
                                @click.prevent="togglePassword"
                              >
                                <i
                                  v-if="state.visible_password"
                                  class="ri-eye-line h5"
                                />
                                <i
                                  v-else
                                  class="ri-eye-off-line h5"
                                />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                  </b-row>
                </b-col>
              </b-row>
              <b-row class="px-2 pt-3">

                <b-overlay
                  class="col-12 px-1"
                  rounded
                  opacity="0.1"
                  spinner-variant="warning"
                  spinner-small
                  :show="state.local"
                >
                  <b-button
                    size="sm"
                    type="submit"
                    class="w-100"
                    variant="success"
                    :disabled="state.busy"
                  >
                    Sign In
                  </b-button>
                </b-overlay>

              </b-row>
            </div>

          </form>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SSharedAuth, SSharedSSO } from '@/services'
import { Oauth2 } from 'universal-social-auth'
import url from '@/mixins/url'
import logos from '@/mixins/logos'

export default {
  name: 'SharedLogin',

  middleware: ['guest'],

  metaInfo: () => ({
    title: 'Sign In'
  }),

  mixins: [
    url,
    logos
  ],

  data: () => ({
    state: {
      busy: false,
      local: false,
      sso: false,
      visible_password: false
    },
    credentials: {
      username: null,
      password: 'P@ssw0rd'
    },
    Oauth2
  }),

  computed: {

    companyLogo () {
      return this.transformToImageUrl(this.getCompany())
    },

    passwordType () {
      return this.state.visible_password ? 'text' : 'password'
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    togglePassword () {
      this.state.visible_password = !this.state.visible_password
    },

    async saveAndGetCurrentUser (token) {
      this.$store.dispatch('auth/saveToken', token)
      return new Promise((resolve, reject) => {
        SSharedAuth.getCurrentUser().then(
          ({ data }) => {
            this.$store.dispatch('auth/fetchUserSuccess', {
              id: data.id,
              email: data.email,
              role: data.role.code,
              role_name: data.role.name,
              username: data.role.username,
              name: data.name,
              location: data.location?.id || null,
              location_name: data.location?.location_name || null,
              location_code: data.location?.location_code || null,
              allow_in: data.location?.allow_in || null,
              allow_out: data.location?.allow_out || null,
              warehouse: data.location?.warehouse || null,
              prefix: data.location?.prefix || null
            })
            const user = this.$store.getters['auth/user']
            this.$router.replace({
              name: user ? `${user.role}.dashboard` : 'dashboard'
            }, () => {
              resolve(data)
            })
          }
        ).catch(error => {
          this.$store.dispatch('auth/fetchUserFailure')
          reject(error)
        })
      })
    },

    async onSignIn () {
      await this.$refs.signinForm.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.state.busy = this.state.local = true
            SSharedAuth.signIn(this.credentials).then(
              ({ data: { token } }) => {
                this.saveAndGetCurrentUser(token).finally(() => {
                  this.state.busy = this.state.local = false
                })
              }
            ).catch(() => {
              this.state.busy = this.state.local = false
            })
          }
        }
      )
    },

    async onSignInWithAljaySSO (event) {
      event.preventDefault()
      this.state.busy = this.state.sso = true
      this.$Oauth.authenticate('oauth2', this.Oauth2).then(
        authorized => {
          SSharedSSO.post(authorized).then(
            ({ data: { token } }) => {
              this.saveAndGetCurrentUser(token).finally(() => {
                this.state.busy = this.state.local = false
              })
            }
          ).catch(() => {
            this.state.busy = this.state.sso = false
          })
        }
      )
    }
  }
}
</script>

  <style scoped>

  .text-divider {
    margin: 2em 0;
    line-height: 0;
    text-align: center;
    color: gray;
    padding: 0px 5px;
  }
  .text-divider span {
    background-color: rgb(255, 255, 255);
    padding: 0 0.5em;
  }
  .text-divider:before {
    content: " ";
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid gray;
    border-bottom: 1px solid #f7f7f7;
  }
  .text-divider span:before  {
    content: "";
    display: block;
    margin-top: -3px;
  }
  .text-divider span:after  {
    display: block;
    margin-top: -3px;
  }
  /* .font-cinzel-decorative {
    font-family: ;
  } */
  @font-face {
      font-family: 'Lato';
      src: url('/assets/lato/Lato-Black.ttf');
  }
  .ifas-text-logo {
    font-size: 30px;
    color: white;
    font-family: 'Lato';
    padding: 0px 10px;
  }
  .logo {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    height: 35vh;
  }
  .sign-in {
    border-radius: 70px 0px 0px 0px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    height: 65vh;
  }

  @media screen and (orientation: landscape) {
    .logo {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
      height: 100vh;
    }

    .sign-in {
      border-radius: 0px 0px 0px 0px;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
      height: 100vh;
    }
  }

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    .sign-in {
      border-radius: 0px 0px 0px 0px;
      /* .welcome-back {
        display: none !important;
      } */
    }
  }

  @media screen and (min-width: 1024px) {

    .logo {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
      height: 100vh;
    }

    .sign-in {
      border-radius: 0px 0px 0px 0px;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      height: 100vh;
    }

  }
  </style>
